import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SiteLogo from "./logo/SiteLogo";
import TTEqualizer from "../common/logo/TTequalizer.json";

function Footer() {
  return (
    <footer className="footer mt-auto py-3">
      <div className="container-fluid">
        <div className="row mr-0 ml-0 text-center text-lg-left">
          <div className="col-12 col-lg-2 text-center">
            <img
              src={require("../../assets/images/metronome_icon_yellow.png")}
              style={{ maxHeight: "80px" }}
              alt="footer icon2"
            />
          </div>
          <div className="col-12 col-lg-7 my-auto text-center">
            <span className="ml-0 ml-lg-5">
              &copy; TilleyTempo / TilleyTech Ltd 2023
            </span>
            <br />
            <div className="d-inline  social-links">
              <a
                className="ml-3"
                href="https://www.facebook.com/tilleytempo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "facebook"]} />
              </a>
              <a
                className="ml-3"
                href="https://www.instagram.com/tilleytempo"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={["fab", "instagram"]} />
              </a>
            </div>
          </div>
          <div className="col-12 col-lg-3 text-center">
            <SiteLogo height={80} width={240} logoColor={TTEqualizer} />
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
